export const useInputImageRenderer = (inputEl, callback) => {
  const inputImageRenderer = () => {
    const file = inputEl.value.files[0]
    const reader = new FileReader()

    reader.addEventListener(
      'load',
      () => {
        callback(reader.result)
      },
      false,
    )

    if (file) {
      reader.readAsDataURL(file)
    }
    console.log("reader",reader)
console.log("file",file)
  }
  const inputImageRendererTab = () => {
    const file = inputEl.value.files[0]
    const reader = new FileReader()

    reader.addEventListener(
      'load',
      () => {
        callback(reader.result)
      },
      false,
    )

    if (file) {
      reader.readAsDataURL(file)
    }
  }
  const inputImageRendererTab3 = () => {
    const file = inputEl.value.files[0]
    const reader = new FileReader()

    reader.addEventListener(
      'load',
      () => {
        callback(reader.result)
      },
      false,
    )

    if (file) {
      reader.readAsDataURL(file)
    }
  }
  const inputImageRendererTab4= () => {
    const file = inputEl.value.files[0]
    const reader = new FileReader()

    reader.addEventListener(
      'load',
      () => {
        callback(reader.result)
      },
      false,
    )

    if (file) {
      reader.readAsDataURL(file)
    }
  }
  const inputImageRendererTab5 = () => {
    const file = inputEl.value.files[0]
    const reader = new FileReader()

    reader.addEventListener(
      'load',
      () => {
        callback(reader.result)
      },
      false,
    )

    if (file) {
      reader.readAsDataURL(file)
    }
  }
  const inputImageRendererTab6 = () => {
    const file = inputEl.value.files[0]
    const reader = new FileReader()

    reader.addEventListener(
      'load',
      () => {
        callback(reader.result)
      },
      false,
    )

    if (file) {
      reader.readAsDataURL(file)
    }
  }
  return {
    inputImageRenderer,inputImageRendererTab,inputImageRendererTab6,inputImageRendererTab5,inputImageRendererTab4,
    inputImageRendererTab3
  }
}

export const _ = null
